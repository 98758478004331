
import { defineComponent, onMounted, computed } from "vue"
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
import { UserOutlined, DownOutlined } from "@ant-design/icons-vue"
import { LOGIN_LOGOUT } from "@/store/actions/auth"
import { ACCOUNT_REQUEST } from "@/store/actions/account"

export default defineComponent({
    name: "Widget-UserMenu",
    components: {
        UserOutlined,
        DownOutlined
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()

        const onLogout = () => {
            store.dispatch(LOGIN_LOGOUT)
        }

        const getAccountInfo = () => {
            store.dispatch(ACCOUNT_REQUEST)
        }

        onMounted(getAccountInfo)

        return {
            t,
            userName: computed(() => store.state.Account.name),
            userEmail: computed(() => store.state.Account.email),
            onLogout,
            accountLoaded: computed(() => store.getters.IS_SESSION_READY)
        }
    }
})

