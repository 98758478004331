import { VueCookieNext } from "vue-cookie-next"
import { IUser } from "@/models/user"

interface IAccount {
    getAccount(): IUser
    saveAccount(user: IUser): void
    removeAccount(): void
}

export class AccountCookie implements IUser, IAccount {
    id: number
    email: string
    name: string
    regDate: Date
    role: number

    constructor() {
        this.id = 0
        this.name = ""
        this.email = ""
        this.regDate = new Date()
        this.role = 0
    }

    getAccount(): IUser {
        let user = new AccountCookie()
        try {
            if (VueCookieNext.isCookieAvailable("Account")) {
                user = VueCookieNext.getCookie("Account")
            }
        }
        catch (error) {
            console.error(error)
        }
        return user
    }

    saveAccount(user: IUser): void {
        const ExpireDate: Date = new Date()
        let ExpireDateString = ""
        ExpireDate.setDate(ExpireDate.getDate() + 1)
        ExpireDateString = ExpireDate.toString()

        if (VueCookieNext.isCookieAvailable("ExpirationDate")) {
            ExpireDateString = new Date(VueCookieNext.getCookie("ExpirationDate")).toString()
        }

        VueCookieNext.setCookie("Account", JSON.stringify(user), { expire: ExpireDateString })
    }

    removeAccount(): void {
        VueCookieNext.removeCookie("Account")
    }
}
