export const INSTANCE_LIST_REQUEST = "INSTANCE_LIST_REQUEST"
export const INSTANCE_LIST_SUCCESS = "INSTANCE_LIST_SUCCESS"
export const INSTANCE_LIST_ERROR = "INSTANCE_LIST_ERROR"

export const INSTANCE_CHANGE_SELECTED = "INSTANCE_CHANGE_SELECTED"

export const INSTANCE_INCIDENTS_REQUEST = "INSTANCE_INCIDENTS_REQUEST"
export const INSTANCE_INCIDENTS_SUCCESS = "INSTANCE_INCIDENTS_SUCCESS"
export const INSTANCE_INCIDENTS_ERROR = "INSTANCE_INCIDENTS_ERROR"

export const INSTANCE_ADD_KEY_REQUEST = "INSTANCE_ADD_KEY_REQUEST"
export const INSTANCE_ADD_KEY_SUCCESS = "INSTANCE_ADD_KEY_SUCCESS"
export const INSTANCE_ADD_KEY_ERROR = "INSTANCE_ADD_KEY_ERROR"
