import { createI18n } from "vue-i18n"
import ru from "./locales/ru.json"
// eslint-disable-next-line no-unused-vars
// import VueCookies from "vue-cookies"
// import ru from "vee-validate/dist/locale/ru"
// import de from "vee-validate/dist/locale/de"
// import es from "vee-validate/dist/locale/es"
// import fr from "vee-validate/dist/locale/fr"
// import it from "vee-validate/dist/locale/it"
// import ru from "vee-validate/dist/locale/ru"

// Type-define 'ru-US' as the master schema for the resource
type MessageSchema = typeof ru

// enum Locales {
//     RU = "ru",
//     FR = "fr",
// }

// function loadLocaleMessages(): MessageSchema {
//     const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
//     const messages: MessageSchema = {}
//     locales.keys().forEach((key) => {
//         const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//         if (matched && matched.length > 1) {
//             const locale: string = matched[1]
//             messages[locale] = locales(key)
//             // switch (locale) {
//             // case "de":
//             //     messages[locale].validation = de.messages
//             //     break
//             // case "es":
//             //     messages[locale].validation = es.messages
//             //     break
//             // case "fr":
//             //     messages[locale].validation = fr.messages
//             //     break
//             // case "it":
//             //     messages[locale].validation = it.messages
//             //     break
//             // case "ru":
//             //     messages[locale].validation = ru.messages
//             //     break
//             // default:
//             //     messages[locale].validation = ru.messages
//             //     break
//             // }
//         }
//     })
//     return messages
// }

// const LangCode = (window.$cookies.isKey("Language") && window.$cookies.get("Language").code)
//     ? window.$cookies.get("Language").code.substr(0, 2)
//     : process.env.VUE_APP_I18N_LOCALE

const i18n = createI18n<[MessageSchema], "ru">({
    // locale: getCurrentLanguage() || "ru",
    legacy: false,
    locale: "ru",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
    messages: {
        ru: ru
    }
})

export default i18n
