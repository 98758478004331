<template>
    <div class="layout-empty">
        <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<style>
    .layout-empty {
        height: 100vh;
    }
    .layout-empty > div {
        height: 100%;
    }
</style>
