import axios from "axios"
import store from "./store"
import { LOGIN_LOGOUT } from "@/store/actions/auth"

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

export default HTTP

// Unauthorized routine
HTTP.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        // if you ever get an unauthorized, logout the user
        store.dispatch(LOGIN_LOGOUT)
        return Promise.reject(error)
    }
    return Promise.reject(error)
})
