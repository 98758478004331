import { createRouter, createWebHistory, RouteLocationNormalized, RouteMeta, RouteRecordRaw } from "vue-router"
import store from "./store/index"
import Main from "./views/Main.vue"
import i18n from "@/i18n"

interface IRouteMeta extends RouteMeta {
    // layout: string
    // requiredAuth: boolean
    // title?: string
    accessRole?: Array<string>
}

interface IRoute extends RouteLocationNormalized {
    meta: IRouteMeta
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Main",
        component: Main,
        meta: {
            layout: "Empty",
            requiredAuth: false
        },
        redirect: () => {
            if (store.getters.IS_AUTHENTICATED) {
                if (store.getters.USER_ROLE === "Admin") {
                    return { name: "Licenses-All" }
                }
                else if (store.getters.USER_ROLE === "Partner") {
                    return { name: "Licenses" }
                }
                else {
                    return { name: "Instances" }
                }
            }
            else {
                return { name: "Login" }
            }
        }
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            layout: "Empty",
            requiredAuth: false,
            title: i18n.global.t("Auth.Interface.Login.Title")
        },
        component: () => import("./views/Auth/Login.vue")
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            layout: "Empty",
            requiredAuth: false,
            title: i18n.global.t("Auth.Interface.Register.Title")
        },
        component: () => import("./views/Auth/Register.vue")
    },
    {
        path: "/account",
        name: "Account",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Account.Title"),
            accessRole: ["Customer", "Partner", "Admin"]
        },
        component: () => import("./views/Account/Index.vue")
    },
    {
        path: "/changepwd",
        name: "Password-Change",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Account.PwdChange.Title"),
            accessRole: ["Customer", "Partner", "Admin"]
        },
        component: () => import("./views/Account/PasswordChange.vue")
    },
    {
        path: "/download",
        name: "Download",
        meta: {
            requiredAuth: false,
            title: i18n.global.t("Anticolumbine.Interface.Download.Title"),
            accessRole: ["Customer", "Partner", "Admin"]
        },
        component: () => import("./views/Download/Index.vue")
    },
    {
        path: "/instances",
        name: "Instances",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Instances.Index.Title"),
            accessRole: ["Customer"]
        },
        component: () => import("./views/Instances/Index.vue")
    },
    {
        path: "/instance/add",
        name: "Instance-Add",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Instances.AddKey.Title"),
            accessRole: ["Customer"]
        },
        component: () => import("./views/Instances/AddKey.vue")
    },
    {
        path: "/instance/dashboard/:id",
        name: "Instance-Dashboard",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Instances.Dashboard.ShortTitle"),
            accessRole: ["Customer"]
        },
        component: () => import("./views/Instances/Dashboard.vue")
    },
    {
        path: "/instance/incidents/:id",
        name: "Instance-Incidents",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Instances.Gallery.ShortTitle"),
            accessRole: ["Customer"]
        },
        component: () => import("./views/Instances/Gallery.vue")
    },
    {
        path: "/instance/details/:id",
        name: "Instance-Details",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Instances.Details.ShortTitle"),
            accessRole: ["Customer"]
        },
        component: () => import("./views/Instances/Details.vue")
    },
    {
        path: "/licenses",
        name: "Licenses",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Licenses.Index.Title"),
            accessRole: ["Partner"]
        },
        component: () => import("./views/Licenses/Partner.vue")
    },
    {
        path: "/alllicenses",
        name: "Licenses-All",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Licenses.Index.Title"),
            accessRole: ["Admin"]
        },
        component: () => import("./views/Licenses/Admin.vue")
    },
    {
        path: "/license/create",
        name: "License-Create",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Licenses.Create.Title"),
            accessRole: ["Admin"]
        },
        component: () => import("./views/Licenses/Create.vue")
    },
    {
        path: "/users",
        name: "Users",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Users.Title"),
            accessRole: ["Admin"]
        },
        component: () => import("./views/Users/Index.vue")
    },
    {
        path: "/user/create",
        name: "User-Create",
        meta: {
            requiredAuth: true,
            title: i18n.global.t("Anticolumbine.Interface.Users.Create.Title"),
            accessRole: ["Admin"]
        },
        component: () => import("./views/Users/Create.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// eslint-disable-next-line @typescript-eslint/ban-types
router.beforeEach((to: IRoute, from: RouteLocationNormalized, next: Function) => {
    // make a title for a pages
    if (window.matchMedia("(display-mode: minimal-ui)").matches) {
        document.title = <string>to.meta.title
    }
    else {
        document.title = <string>to.meta.title + " | " + process.env.VUE_APP_TITLE
    }

    // check auth
    if (to.meta.RequiresAuth && !store.getters.IS_AUTHENTICATED) {
        next({ name: "Login" })
    }
    if (to.name === "Login" && store.getters.IS_AUTHENTICATED) {
        next({ name: "Main" })
    }

    // check user's role before enter
    if ("accessRole" in to.meta === false) {
        next()
    }
    else if (
        to.meta.accessRole &&
        store.getters.USER_ROLE &&
        store.getters.USER_ROLE !== "" &&
        to.meta.accessRole.includes(store.getters.USER_ROLE)
    ) {
        next()
    }
    else {
        next({ name: "Main" })
    }
})

export default router
