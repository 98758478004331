
import Default from "@/layouts/Default.vue"
import Empty from "@/layouts/Empty.vue"
import { defineComponent, ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { updatePWA } from "@/composables/updatePWA"
import { TOKEN_REFRESH_REQUEST } from "@/store/actions/auth"

export default defineComponent({
    components: {
        Default,
        Empty
    },
    setup() {
        const layout = ref<string | unknown>()
        const router = useRouter()
        const store = useStore()
        const { setupUpdatePWA } = updatePWA()

        setupUpdatePWA()

        router.afterEach((to) => {
            if (to.meta.layout !== undefined) {
                layout.value = to.meta.layout
            }
            else {
                layout.value = "Default" // this is default layout if route meta is not set
            }
        })

        const refreshToken = () => {
            if (store.getters.IS_AUTHENTICATED) {
                store.dispatch(TOKEN_REFRESH_REQUEST)
            }
        }

        onMounted(refreshToken)

        return {
            layout
        }
    }
})
