import { ref, Ref, h } from "vue"
import { notification, Button } from "ant-design-vue"

interface IUpdatePWA {
    setupUpdatePWA(): void
}

export function updatePWA(): IUpdatePWA {
    const refreshing = ref(false)
    const registration = ref(null) as Ref<Record<any, any>> | Ref<null>
    const updateExists = ref(false)

    function setupUpdatePWA() {
        // Listen for our custom event from the SW registration
        document.addEventListener("swUpdated", updateAvailable, { once: true })

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (refreshing.value) return
            refreshing.value = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    }

    function openNotification() {
        const key = `open${Date.now()}`
        notification.open({
            message: "Доступно обновление",
            description: "Выполните обновление для того, чтобы использовать новейшую версия приложения",
            btn: h(
                Button,
                {
                    type: "primary",
                    size: "large",
                    onClick: () => {
                        refreshApp()
                        notification.close(key)
                    }
                },
                "Обновить"
            ),
            key,
            placement: "bottomRight",
            duration: 300,
            onClose: close
        })
    }

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    function updateAvailable(event: Record<any, any>) {
        console.log(event.detail)
        registration.value = event.detail
        updateExists.value = true
        console.log("Trying to show notification about update")
        openNotification()
    }

    // Called when the user accepts the update
    function refreshApp() {
        updateExists.value = false
        // Make sure we only send a 'skip waiting' message if the SW is waiting
        if (!registration.value || !registration.value.waiting) return
        // send message to SW to skip the waiting and activate the new SW
        console.log("Trying to refresh page")
        registration.value.waiting.postMessage({ type: "SKIP_WAITING" })
    }

    return {
        setupUpdatePWA
    }
}
