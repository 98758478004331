import { Commit } from "vuex"
import { AxiosError } from "axios"
import HTTP from "@/http"
import i18n from "@/i18n"
import { AccountCookie } from "@/utils/Account"
import { NotificationAntD } from "@/utils/Notification"
import { IUser } from "@/models/user"
import { IPasswordChange } from "@/models/password"
import { IWebResponse } from "@/models/webResponse"
import {
    ACCOUNT_REQUEST,
    ACCOUNT_REQUEST_ERROR,
    ACCOUNT_REQUEST_SUCCESS,

    PASSWORD_CHANGE_REQUEST,
    PASSWORD_CHANGE_ERROR,
    PASSWORD_CHANGE_SUCCESS
} from "../actions/account"

const Account = new AccountCookie()
const Notification = new NotificationAntD()

interface IState extends IUser {
    Status: string
}

const state: IState = <IState>Account.getAccount()

const getters = {
    IS_SESSION_READY: (state: IState): boolean => !!state.email && state.email !== "" && !!state.id && state.id !== 0
    // IS_EMAIL_CONFIRMED: (state: IState): boolean => state.IsEmailConfirmed,
    // IS_PHONE_VERIFIED: (state: IState): boolean => state.IsPhoneVerified
}

const actions = {
    [ACCOUNT_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(ACCOUNT_REQUEST)
            HTTP({
                url: "account/whoami",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.id) {
                        commit(ACCOUNT_REQUEST_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(ACCOUNT_REQUEST_ERROR, error.response?.data)
                })
        }),

    [PASSWORD_CHANGE_REQUEST]: ({ commit }: { commit: Commit }, payload: IPasswordChange): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(PASSWORD_CHANGE_REQUEST)
            HTTP({
                url: "account/changepassword",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(PASSWORD_CHANGE_SUCCESS)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(PASSWORD_CHANGE_ERROR, error.response?.data)
                })
        })
}

const mutations = {
    [ACCOUNT_REQUEST]: (state: IState): void => {
        state.Status = "account info requested"
    },
    [ACCOUNT_REQUEST_SUCCESS]: (state: IState, payload: IUser): void => {
        try {
            state.id = payload.id
            state.name = payload.name
            state.email = payload.email
            state.regDate = new Date(payload.regDate)
            Account.saveAccount(payload)
            state.Status = "account info successfully received"
        }
        catch (error) {
            state.Status = `account info receive error: ${error}`
        }
    },
    [ACCOUNT_REQUEST_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "account info request error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [PASSWORD_CHANGE_REQUEST]: (state: IState): void => {
        state.Status = "changing password is requested"
    },
    [PASSWORD_CHANGE_SUCCESS]: (state: IState): void => {
        Notification.showNotification(i18n.global.t("Auth.Message.Password.ChangeSuccess"), "success")
        state.Status = "changing password is successful"
    },
    [PASSWORD_CHANGE_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "changing password error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
