import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!

  return (_openBlock(), _createBlock(_component_a_layout_footer, { style: {"text-align":"center"} }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.appName) + " © " + _toDisplayString(_ctx.currentYear) + " [v." + _toDisplayString(_ctx.version) + "] ", 1)
    ]),
    _: 1
  }))
}