import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  href: "https://anticolumbine.ru",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_a_page_header = _resolveComponent("a-page-header")!

  return (_openBlock(), _createBlock(_component_a_page_header, { style: {"border":"1px solid rgb(235, 237, 240)"} }, {
    tags: _withCtx(() => [
      _createElementVNode("a", _hoisted_1, _toDisplayString(_ctx.appName), 1)
    ]),
    extra: _withCtx(() => [
      _createVNode(_component_UserMenu)
    ]),
    _: 1
  }))
}