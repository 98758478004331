import { message } from "ant-design-vue"

interface INotification {
    showNotification(text: string, type?: string, duration?: number): void
}

export class NotificationAntD implements INotification {
    showNotification(text: string, type?: string, duration = 5): void {
        switch (type) {
        case "success":
            message.success(text, duration)
            break
        case "warning":
            message.warning(text, duration)
            break
        case "error":
            message.error(text, duration)
            break
        default:
            message.info(text, duration)
            break
        }
    }
}
