
import { useRoute } from "vue-router"
import { defineComponent, reactive, toRefs, ref, computed, onMounted } from "vue"
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
import {
    VerifiedOutlined,
    KeyOutlined,
    CloudServerOutlined,
    // LineChartOutlined,
    VideoCameraOutlined,
    TeamOutlined,
    DownloadOutlined
} from "@ant-design/icons-vue"
import { INSTANCE_LIST_REQUEST } from "@/store/actions/instance"

interface IState {
    selectedKeys: Array<string>
    openKeys: Array<string>
}

export default defineComponent({
    name: "Sidebar",
    components: {
        VerifiedOutlined,
        KeyOutlined,
        CloudServerOutlined,
        // LineChartOutlined,
        VideoCameraOutlined,
        TeamOutlined,
        DownloadOutlined
    },
    setup() {
        const { t } = useI18n()
        const route = useRoute()
        const store = useStore()

        const state: IState = reactive({
            selectedKeys: (typeof route.path === "string") ? [route.path] : [],
            openKeys: (route.params.id && typeof route.params.id === "string") ? [route.params.id] : []
        })

        const getInstances = () => {
            if (!store.getters.IS_INSTANCES_LOADED && store.getters.USER_ROLE === "Customer") {
                store.dispatch(INSTANCE_LIST_REQUEST)
            }
        }

        onMounted(getInstances)

        return {
            t,
            ...toRefs(state),
            collapsed: ref<boolean>(false),
            userRole: computed(() => store.getters.USER_ROLE),
            instances: computed(() => store.state.Instance.Instances),
            instancesLoaded: computed(() => store.getters.IS_INSTANCES_LOADED)
        }
    }
})
