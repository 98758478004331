import { Commit } from "vuex"
import { AxiosError } from "axios"
import HTTP from "@/http"
import i18n from "@/i18n"
import { NotificationAntD } from "@/utils/Notification"
import { IUser } from "@/models/user"
import { IWebResponse } from "@/models/webResponse"
import {
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_ERROR,

    USER_CREATE_REQUEST,
    USER_CREATE_SUCCESS,
    USER_CREATE_ERROR
} from "../actions/user"

const Notification = new NotificationAntD()

interface IState {
    Status: string
    Users: Array<IUser>
    Loaded: boolean
}

const state: IState = {
    Status: "",
    Users: [],
    Loaded: false
}

const getters = {
    USERS: (state: IState): Array<IUser> => {
        if (!!state.Users && state.Users.length > 0) {
            return state.Users
        }
        else {
            return []
        }
    },
    IS_USERS_LOADED: (state: IState): boolean => !!state.Loaded
}

const actions = {
    [USER_LIST_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(USER_LIST_REQUEST)
            HTTP({
                url: "admin/users",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(USER_LIST_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(USER_LIST_ERROR, error.response?.data)
                })
        }),

    [USER_CREATE_REQUEST]: ({ commit }: { commit: Commit }, payload: IUser): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(USER_CREATE_REQUEST)
            HTTP({
                url: "admin/createuser",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 204) {
                        commit(USER_CREATE_SUCCESS)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(USER_CREATE_ERROR, error.response?.data)
                })
        })
}

const mutations = {
    [USER_LIST_REQUEST]: (state: IState): void => {
        state.Status = "getting users list request"
    },
    [USER_LIST_SUCCESS]: (state: IState, payload: Array<IUser>): void => {
        try {
            state.Users = payload.map((user: IUser) => ({
                id: user.id,
                email: user.email,
                name: user.name,
                regDate: new Date(user.regDate),
                role: user.role
            }))
            state.Loaded = true
            state.Status = "getting users list success"
        }
        catch (error) {
            state.Status = `login error: ${error}`
        }
    },
    [USER_LIST_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "getting users list error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [USER_CREATE_REQUEST]: (state: IState): void => {
        state.Status = "user create request"
    },
    [USER_CREATE_SUCCESS]: (state: IState): void => {
        state.Status = "user create success"
    },
    [USER_CREATE_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "user create error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
