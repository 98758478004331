import { Commit } from "vuex"
import { AxiosError } from "axios"
import HTTP from "@/http"
import i18n from "@/i18n"
import { NotificationAntD } from "@/utils/Notification"
import { ILicensePartner, ILicenseAdmin, ILicenseNew } from "@/models/license"
import { IWebResponse } from "@/models/webResponse"
import {
    LICENSE_PARTNER_LIST_REQUEST,
    LICENSE_PARTNER_LIST_SUCCESS,
    LICENSE_PARTNER_LIST_ERROR,

    LICENSE_ADMIN_LIST_REQUEST,
    LICENSE_ADMIN_LIST_SUCCESS,
    LICENSE_ADMIN_LIST_ERROR,

    LICENSE_ISSUE_REQUEST,
    LICENSE_ISSUE_SUCCESS,
    LICENSE_ISSUE_ERROR
} from "../actions/license"

const Notification = new NotificationAntD()

interface IState {
    Status: string
    PartnerLicenses: Array<ILicensePartner>
    PartnerLicensesLoaded: boolean
    AdminLicenses: Array<ILicenseAdmin>
    AdminLicensesLoaded: boolean
}

const state: IState = {
    Status: "",
    PartnerLicenses: [],
    PartnerLicensesLoaded: false,
    AdminLicenses: [],
    AdminLicensesLoaded: false
}

const getters = {
    PARTNER_LICENSES: (state: IState): Array<ILicensePartner> => {
        if (!!state.PartnerLicenses && state.PartnerLicenses.length > 0) {
            return state.PartnerLicenses
        }
        else {
            return []
        }
    },
    IS_PARTNER_LICENSES_LOADED: (state: IState): boolean => !!state.PartnerLicensesLoaded,
    ADMIN_LICENSES: (state: IState): Array<ILicenseAdmin> => {
        if (!!state.AdminLicenses && state.AdminLicenses.length > 0) {
            return state.AdminLicenses
        }
        else {
            return []
        }
    },
    IS_ADMIN_LICENSES_LOADED: (state: IState): boolean => !!state.AdminLicensesLoaded
}

const actions = {
    [LICENSE_PARTNER_LIST_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(LICENSE_PARTNER_LIST_REQUEST)
            HTTP({
                url: "partner/mylicenses",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(LICENSE_PARTNER_LIST_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(LICENSE_PARTNER_LIST_ERROR, error.response?.data)
                })
        }),

    [LICENSE_ADMIN_LIST_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(LICENSE_ADMIN_LIST_REQUEST)
            HTTP({
                url: "admin/licenses",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(LICENSE_ADMIN_LIST_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(LICENSE_ADMIN_LIST_ERROR, error.response?.data)
                })
        }),

    [LICENSE_ISSUE_REQUEST]: ({ commit }: { commit: Commit }, payload: ILicenseNew): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(LICENSE_ISSUE_REQUEST)
            HTTP({
                url: "admin/issuelicense",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(LICENSE_ISSUE_SUCCESS)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(LICENSE_ISSUE_ERROR, error.response?.data)
                })
        })
}

const mutations = {
    [LICENSE_PARTNER_LIST_REQUEST]: (state: IState): void => {
        state.Status = "getting partner licenses list request"
    },
    [LICENSE_PARTNER_LIST_SUCCESS]: (state: IState, payload: Array<ILicensePartner>): void => {
        try {
            state.PartnerLicenses = payload.map((license: ILicensePartner) => ({
                id: license.id,
                description: license.description,
                customerName: license.customerName,
                customerEmail: license.customerEmail,
                purchaseDate: new Date(license.purchaseDate),
                validThru: new Date(license.validThru),
                camLimit: license.camLimit,
                camActive: license.camActive
            }))
            state.PartnerLicensesLoaded = true
            state.Status = "getting partner licenses list success"
        }
        catch (error) {
            state.Status = `setting partner licenses list error: ${error}`
        }
    },
    [LICENSE_PARTNER_LIST_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "getting partner licenses list error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [LICENSE_ADMIN_LIST_REQUEST]: (state: IState): void => {
        state.Status = "getting admin licenses list request"
    },
    [LICENSE_ADMIN_LIST_SUCCESS]: (state: IState, payload: Array<ILicenseAdmin>): void => {
        try {
            state.AdminLicenses = payload.map((license: ILicenseAdmin) => ({
                id: license.id,
                key: license.key,
                description: license.description,
                partnerName: license.partnerName,
                partnerEmail: license.partnerEmail,
                customerName: license.customerName,
                customerEmail: license.customerEmail,
                purchaseDate: new Date(license.purchaseDate),
                validThru: new Date(license.validThru),
                camLimit: license.camLimit,
                camActive: license.camActive
            }))
            state.AdminLicensesLoaded = true
            state.Status = "getting admin licenses list success"
        }
        catch (error) {
            state.Status = `setting admin licenses list error: ${error}`
        }
    },
    [LICENSE_ADMIN_LIST_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "getting admin licenses list error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [LICENSE_ISSUE_REQUEST]: (state: IState): void => {
        state.Status = "creating new license is requsted"
    },
    [LICENSE_ISSUE_SUCCESS]: (state: IState): void => {
        state.Status = "creating new license is successfull"
    },
    [LICENSE_ISSUE_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "creating new license error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
