
import { defineComponent } from "vue"
// import { useStore } from "vuex"
// import InstanceSwitcher from "@/components/Widget/InstanceSwitcher.vue"
import UserMenu from "@/components/Widget/UserMenu.vue"

export default defineComponent({
    name: "Header",
    components: {
        // InstanceSwitcher,
        UserMenu
    },
    setup() {
        return {
            appName: process.env.VUE_APP_SHORT_TITLE
        }
    }
})
