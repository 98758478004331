
import { defineComponent } from "vue"
import { version } from "../../package.json"

export default defineComponent({
    name: "Footer",
    setup() {
        return {
            version,
            currentYear: new Date().getFullYear(),
            appName: process.env.VUE_APP_TITLE
        }
    }
})
