import { Commit } from "vuex"
import { AxiosError, AxiosResponse } from "axios"
import HTTP from "@/http"
import i18n from "@/i18n"
import { NotificationAntD } from "@/utils/Notification"
import { IInstance, IAddKey, IInstanceImagePaginator } from "@/models/instance"
import { IWebResponse } from "@/models/webResponse"
import {
    INSTANCE_LIST_REQUEST,
    INSTANCE_LIST_SUCCESS,
    INSTANCE_LIST_ERROR,

    INSTANCE_CHANGE_SELECTED,

    INSTANCE_INCIDENTS_REQUEST,
    INSTANCE_INCIDENTS_SUCCESS,
    INSTANCE_INCIDENTS_ERROR,

    INSTANCE_ADD_KEY_REQUEST,
    INSTANCE_ADD_KEY_SUCCESS,
    INSTANCE_ADD_KEY_ERROR
} from "../actions/instance"

const Notification = new NotificationAntD()

function getSavedSelectedInstance(): number {
    const result = localStorage.getItem("SelectedInstance")
    return (result) ? parseInt(result, 10) : 0
}

interface IState {
    Status: string
    Instances: Array<IInstance>
    SelectedInstance: number,
    Loaded: boolean,
    Images: Array<string>
}

const state: IState = {
    Status: "",
    Instances: [],
    SelectedInstance: getSavedSelectedInstance(),
    Loaded: false,
    Images: []
}

const getters = {
    INSTANCES: (state: IState): Array<IInstance> => {
        if (!!state.Instances && state.Instances.length > 0) {
            return state.Instances
        }
        else {
            return []
        }
    },
    IS_INSTANCES_LOADED: (state: IState): boolean => !!state.Loaded,
    GET_INSTANCE_BY_ID: (state: IState) => (id: number): IInstance | undefined => state.Instances.find((x: IInstance) => x.id === id),
    SELECTED_INSTANCE: (state: IState): number => state.SelectedInstance,
    IS_INSTANCE_INCIDENTS_LOADED: (state: IState): boolean => !!state.Images && state.Images.length > 0
}

const actions = {
    [INSTANCE_LIST_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(INSTANCE_LIST_REQUEST)
            HTTP({
                url: "customer/instances",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(INSTANCE_LIST_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(INSTANCE_LIST_ERROR, error.response?.data)
                })
        }),

    [INSTANCE_CHANGE_SELECTED]: ({ commit }: { commit: Commit }, payload: number): Promise<boolean> =>
        new Promise((resolve) => {
            commit(INSTANCE_CHANGE_SELECTED, payload)
            resolve(true)
        }),

    [INSTANCE_INCIDENTS_REQUEST]: ({ commit }: { commit: Commit }, payload: IInstanceImagePaginator): Promise<AxiosResponse> =>
        new Promise((resolve, reject) => {
            commit(INSTANCE_INCIDENTS_REQUEST)
            HTTP({
                url: "image/list",
                params: payload,
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        commit(INSTANCE_INCIDENTS_SUCCESS, response.data)
                        resolve(response)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        // resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(INSTANCE_INCIDENTS_ERROR, error.response?.data)
                })
        }),

    [INSTANCE_ADD_KEY_REQUEST]: ({ commit }: { commit: Commit }, payload: IAddKey): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(INSTANCE_ADD_KEY_REQUEST)
            HTTP({
                url: "customer/attachlicense",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 204) {
                        commit(INSTANCE_ADD_KEY_SUCCESS)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(INSTANCE_ADD_KEY_ERROR, error.response?.data)
                })
        })
}

const mutations = {
    [INSTANCE_LIST_REQUEST]: (state: IState): void => {
        state.Status = "getting instance list request"
    },
    [INSTANCE_LIST_SUCCESS]: (state: IState, payload: Array<IInstance>): void => {
        try {
            state.Instances = payload.map((instance: IInstance) => ({
                id: instance.id,
                key: instance.key,
                description: instance.description,
                camActive: instance.camActive,
                camLimit: instance.camLimit,
                purchaseDate: new Date(instance.purchaseDate),
                validThru: new Date(instance.validThru)
            }))
            state.Loaded = true
            // if (state.SelectedInstance === 0 && state.Instances.length > 0) {
            //     state.SelectedInstance = state.Instances[0].id
            //     localStorage.setItem("SelectedInstance", state.Instances[0].id.toString(10))
            // }
            state.Status = "getting instance list success"
        }
        catch (error) {
            state.Status = `login error: ${error}`
        }
    },
    [INSTANCE_LIST_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "getting instance list error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [INSTANCE_CHANGE_SELECTED]: (state: IState, payload: number): void => {
        try {
            state.SelectedInstance = payload
            localStorage.setItem("SelectedInstance", payload.toString(10))
            state.Status = "selected instance is changed"
        }
        catch (error) {
            state.Status = "error when trying to change selected instance"
        }
    },

    [INSTANCE_INCIDENTS_REQUEST]: (state: IState): void => {
        state.Status = "getting images requsted"
    },
    [INSTANCE_INCIDENTS_SUCCESS]: (state: IState, payload: Array<string>): void => {
        try {
            state.Images = payload
            state.Status = "getting images success"
        }
        catch (error) {
            state.Status = `getting images error: ${error}`
        }
    },
    [INSTANCE_INCIDENTS_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "getting images error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    },

    [INSTANCE_ADD_KEY_REQUEST]: (state: IState): void => {
        state.Status = "adding license key is requsted"
    },
    [INSTANCE_ADD_KEY_SUCCESS]: (state: IState): void => {
        state.Status = "adding license key is successfull"
    },
    [INSTANCE_ADD_KEY_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "adding license key error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
