import { Commit } from "vuex"
import { AxiosError } from "axios"
import HTTP from "@/http"
import i18n from "@/i18n"
import { AuthTokenCookie } from "@/utils/Token"
import { AccountCookie } from "@/utils/Account"
import { NotificationAntD } from "@/utils/Notification"
import { ILogin, ILoginResponse } from "@/models/login"
import { IJwtToken } from "@/models/token"
import { IWebResponse } from "@/models/webResponse"
import IRegister from "@/models/register"
import {
    LOGIN_REQUEST,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN_LOGOUT,

    TOKEN_REFRESH_REQUEST,

    REGISTER_REQUEST,
    REGISTER_ERROR,
    REGISTER_SUCCESS
} from "../actions/auth"

const AuthToken = new AuthTokenCookie()
const Account = new AccountCookie()
const Notification = new NotificationAntD()

interface IState extends ILoginResponse {
    Status: string,
    JWT: IJwtToken | null
}

const state: IState = {
    Status: "",
    token: AuthToken.getToken().AuthToken,
    expirationDate: AuthToken.getToken().ExpirationDate,
    JWT: (AuthToken.getToken().AuthToken)
        ? AuthToken.beautifyToken(AuthToken.decodeToken(AuthToken.getToken().AuthToken))
        : null
}

const getters = {
    IS_AUTHENTICATED: (state: IState): boolean => !!state.token && state.token !== "",
    USER_ROLE: (state: IState): string => (state.JWT !== null) ? state.JWT.role : ""
}

const actions = {
    [LOGIN_REQUEST]: ({ commit }: { commit: Commit }, payload: ILogin): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(LOGIN_REQUEST)
            HTTP({
                url: "account/login",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.token && response.data.expirationDate) {
                        commit(LOGIN_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(LOGIN_ERROR, error.response?.data)
                })
        }),
    [LOGIN_LOGOUT]: ({ commit }: { commit: Commit }, payload: string): Promise<boolean> =>
        new Promise((resolve) => {
            commit(LOGIN_LOGOUT, payload)
            // commit(SESSION_LOGOUT)
            resolve(true)
        }),

    [TOKEN_REFRESH_REQUEST]: ({ commit }: { commit: Commit }): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(TOKEN_REFRESH_REQUEST)
            HTTP({
                url: "account/refreshtoken",
                method: "GET"
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data && response.data.token && response.data.expirationDate) {
                        commit(LOGIN_SUCCESS, response.data)
                        resolve(true)
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(LOGIN_ERROR, error.response?.data)
                })
        }),

    [REGISTER_REQUEST]: ({ commit }: { commit: Commit }, payload: IRegister): Promise<boolean> =>
        new Promise((resolve, reject) => {
            commit(REGISTER_REQUEST)
            HTTP({
                url: "account/register",
                data: payload,
                method: "POST"
            })
                .then((response) => {
                    if (response && response.status === 200) {
                        commit(REGISTER_SUCCESS)
                        resolve(true)
                        // const loginData: ILogin = {
                        //     login: payload.email,
                        //     password: payload.password
                        // }
                        // dispatch(LOGIN_REQUEST, loginData)
                        //     .then(() => data.router.push({ name: "VerifyPhone" }))
                    }
                    else {
                        Notification.showNotification(i18n.global.t("Auth.Message.Backend.NoData"), "error")
                        resolve(false)
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                    commit(REGISTER_ERROR, error.response?.data)
                })
        })
}

const mutations = {
    [LOGIN_REQUEST]: (state: IState): void => {
        state.Status = "login requested"
    },
    [LOGIN_SUCCESS]: (state: IState, payload: ILoginResponse): void => {
        try {
            state.token = `Bearer ${payload.token}`
            state.expirationDate = payload.expirationDate
            AuthToken.saveToken(state.token, state.expirationDate)
            // console.log(AuthToken.decodeToken(state.token))
            state.JWT = AuthToken.beautifyToken(AuthToken.decodeToken(state.token))
            HTTP.defaults.headers.common.Authorization = state.token
            state.Status = "login success"
        }
        catch (error) {
            state.Status = `login error: ${error}`
        }
    },
    [LOGIN_ERROR]: (state: IState, payload: IWebResponse): void => {
        try {
            state.Status = "login error"
            state.token = ""
            AuthToken.removeToken()
            Account.removeAccount()
            delete HTTP.defaults.headers.common.Authorization
            if (payload && payload.message) {
                Notification.showNotification(payload.message, "error")
            }
            else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
        }
        catch (error) {
            state.Status = `login error: ${error}`
        }
    },
    [LOGIN_LOGOUT]: (state: IState, payload: string): void => {
        try {
            state.Status = ""
            state.token = ""
            AuthToken.removeToken()
            Account.removeAccount()
            if (!payload) { window.location.href = "/login" }
        }
        catch {
            window.location.href = "/"
        }
    },

    [TOKEN_REFRESH_REQUEST]: (state: IState): void => {
        state.Status = "token refresh requested"
    },

    [REGISTER_REQUEST]: (state: IState): void => {
        state.Status = "register requested"
    },
    [REGISTER_SUCCESS]: (state: IState): void => {
        state.Status = "register success"
        Notification.showNotification(i18n.global.t("Auth.Message.Register.Success"), "warning")
    },
    [REGISTER_ERROR]: (state: IState, payload: IWebResponse): void => {
        state.Status = "register error"
        if (payload && payload.message) {
            Notification.showNotification(payload.message, "error")
        }
        else { Notification.showNotification(i18n.global.t("Auth.Message.Backend.Default"), "error") }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
