
import Header from "@/components/Header.vue"
import Sidebar from "@/components/Sidebar.vue"
import Footer from "@/components/Footer.vue"
import { defineComponent } from "vue"

export default defineComponent({
    components: {
        Header,
        Sidebar,
        Footer
    }
})
