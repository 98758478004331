import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    spinning: !_ctx.accountLoaded
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_dropdown, { trigger: ['click'] }, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_menu_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: { name: 'Account' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Anticolumbine.Interface.Account.ShortTitle")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_menu_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, { to: { name: 'Password-Change' } }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Anticolumbine.Interface.Account.PwdChange.Title")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_menu_divider),
              _createVNode(_component_a_menu_item, { onClick: _ctx.onLogout }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Anticolumbine.Interface.Account.Logout")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UserOutlined),
              (_ctx.userName)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.userName), 1)
                  ], 64))
                : (_ctx.userEmail)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.userEmail), 1)
                    ], 64))
                  : _createCommentVNode("", true),
              _createVNode(_component_DownOutlined)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["spinning"]))
}