import { VueCookieNext } from "vue-cookie-next"
import HTTP from "@/http"
import { IJwtTokenRecieved, IJwtToken } from "@/models/token"

interface IAuthToken {
    checkToken(): boolean
    getToken(): Record<string, string>
    saveToken(token: string, expire: string): void
    removeToken(): void
    decodeToken(token: string): IJwtTokenRecieved
    beautifyToken(token: IJwtTokenRecieved): IJwtToken
}

export class AuthTokenCookie implements IAuthToken {
    checkToken(): boolean {
        if (VueCookieNext.isCookieAvailable("AuthToken") && VueCookieNext.isCookieAvailable("ExpirationDate")) {
            const expire = new Date(VueCookieNext.getCookie("ExpirationDate"))
            const now = new Date()

            if (now.getTime() < expire.getTime()) {
                // Token expiration date is valid
                return true
            }
            else {
                // Token expiration date is invalid, delete auth cookies
                VueCookieNext.removeCookie("AuthToken")
                VueCookieNext.removeCookie("ExpirationDate")
                return false
            }
        }
        // Cookies can't be founded or not created yet
        return false
    }

    getToken(): Record<string, string> {
        if (this.checkToken()) {
            HTTP.defaults.headers.common.Authorization = VueCookieNext.getCookie("AuthToken")
            return {
                AuthToken: VueCookieNext.getCookie("AuthToken"),
                ExpirationDate: VueCookieNext.getCookie("ExpirationDate")
            }
        }
        return {
            AuthToken: "",
            ExpirationDate: ""
        }
    }

    saveToken(value: string, expireDate: string): void {
        const ExpireDate: string = new Date(expireDate).toString()

        VueCookieNext.setCookie("AuthToken", value, { expire: ExpireDate })
        VueCookieNext.setCookie("ExpirationDate", expireDate, { expire: ExpireDate })
    }

    removeToken(): void {
        VueCookieNext.removeCookie("AuthToken")
        VueCookieNext.removeCookie("ExpirationDate")
    }

    decodeToken(token: string): IJwtTokenRecieved {
        const base64Url = token.split(".")[1]
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        const jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(""))

        return JSON.parse(jsonPayload)
    }

    beautifyToken(token: IJwtTokenRecieved): IJwtToken {
        return {
            email: token.email,
            name: token.name,
            sub: token.sub,
            role: token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
        }
    }
}
