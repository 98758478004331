import { createStore, createLogger } from "vuex"

import Auth from "./modules/auth"
import Account from "./modules/account"
import Instance from "./modules/instance"
import License from "./modules/license"
import User from "./modules/user"

const debug = process.env.NODE_ENV !== "production"
const plugins = debug ? [createLogger({})] : []

export default createStore({
    strict: process.env.NODE_ENV !== "production",
    plugins,
    modules: {
        Auth,
        Account,
        Instance,
        License,
        User
    }
})
