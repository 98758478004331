import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import i18n from "./i18n"

// LAYOUT & STYLES
import Antd from "ant-design-vue"
import "./assets/less/antd.less"
import "./styles.scss"

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(Antd)
    .mount("#app")
