export const LICENSE_PARTNER_LIST_REQUEST = "LICENSE_PARTNER_LIST_REQUEST"
export const LICENSE_PARTNER_LIST_SUCCESS = "LICENSE_PARTNER_LIST_SUCCESS"
export const LICENSE_PARTNER_LIST_ERROR = "LICENSE_PARTNER_LIST_ERROR"

export const LICENSE_ADMIN_LIST_REQUEST = "LICENSE_ADMIN_LIST_REQUEST"
export const LICENSE_ADMIN_LIST_SUCCESS = "LICENSE_ADMIN_LIST_SUCCESS"
export const LICENSE_ADMIN_LIST_ERROR = "LICENSE_ADMIN_LIST_ERROR"

export const LICENSE_ISSUE_REQUEST = "LICENSE_ISSUE_REQUEST"
export const LICENSE_ISSUE_SUCCESS = "LICENSE_ISSUE_SUCCESS"
export const LICENSE_ISSUE_ERROR = "LICENSE_ISSUE_ERROR"
